import Vue from "vue";
import { axios } from "./axios";

Vue.prototype.api = {
  login: (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login/web", { email, password })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  verifyMail: (token) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/mail/verify", { token })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  verifyToken: (token) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/token/verify", { token })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  forgotPassword: (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/forgot/password", { email })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  resetPassword: (token, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/reset/password", { token, password })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  queryExec: (conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/query/exec", conditions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  questionDetails: (categoryId, questionId, conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/question/details", {
          categoryId,
          questionId,
          conditions,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getDiagnosticos: (conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/diagnostico", conditions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getDiagnosticosSpreadsheet: (conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/diagnostico/spreadsheet", conditions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  queryExecSustainable: (conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/sustainable/dashboard/query/exec", conditions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  questionDetailsSustainable: (categoryId, questionId, conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/sustainable/dashboard/question/details", {
          categoryId,
          questionId,
          conditions,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getDiagnosticosSustainable: (conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/sustainable/diagnostico", conditions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getDiagnosticosSpreadsheetSustainable: (conditions) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/sustainable/diagnostico/spreadsheet", conditions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getFilters: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/filters")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getCategories: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/survey/categories")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getSurveyStruct: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/survey/struct")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getCategoriesSustainable: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/survey/sustainable/categories")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getSurveyStructSustainable: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/survey/sustainable/struct")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getCidades: (CodEstado) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/cidades", { params: { CodEstado } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getAllCidades: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/cidades")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getEstados: () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/estados")
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  list: (entity) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${entity}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  listMini: (entity) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${entity}/mini`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  show: (entity, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${entity}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  save: (entity, form) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/${entity}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  update: (entity, id, form) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/${entity}/${id}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  delete: (entity, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${entity}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getConsultor: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/consultor/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },

  // LactPro
  getEntidade: (entidade, id) => {
    return axios.get("/" + entidade + "/" + id);
  },
  listEntidade: (entidade, config) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/" + entidade, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  paises: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve([{ nome: "Brasil", id: 1 }]), 0);
    });
  },
  estados: (codPais) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/configs/estados/" + codPais)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  cidades: (codPais, codEstado) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/cidades/" + codPais + "/" + codEstado)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  cadastrarEntidade: (form, entidade) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/${entidade}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  salvarEntidade: (form, entidade, id) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/${entidade}/${id}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  inativarEntidade: (entidade, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${entidade}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  getPessoa: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/pessoa/dados", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  deleteCadFaz: (id, produtorId, fazendaId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("produtorFazenda/" + id + "/" + produtorId + "/" + fazendaId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  deleteFaz: (idFaz, idProd) => {
    return new Promise((resolve, reject) => {
      axios
        .delete("produtor/" + idProd + "/fazenda/" + idFaz)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  ativarCadFaz: (id, produtorId, fazendaId) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          "produtorFazenda/ativar/" + id + "/" + produtorId + "/" + fazendaId
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
  inativarCadFaz: (id, produtorId, fazendaId) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          "produtorFazenda/inativar/" + id + "/" + produtorId + "/" + fazendaId
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },

  getSignature: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get("images/signature/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },

  getSignatureUrl: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get("images/url/signature/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((_err) => {
          reject(_err.data);
        });
    });
  },
};

export default Vue.prototype.api;

export default {
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  drawer: null,
  user: null,
  snackbar: {
    show: false,
    message: null,
  },
  errorView: {
    error: false,
    message: null,
  },
  drawer_selected: [],
}

import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const DELETE_CAD = 'DELETE_CAD'
export const SET_PRODUTOR_SELECIONADO = 'SET_PRODUTOR_SELECIONADO'
export const PUSH_CAD = 'PUSH_CAD'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const INATIVAR_PRODUTOR = 'INATIVAR_PRODUTOR'
export const ATIVAR_CAD_FAZENDA = 'ATIVAR_CAD_FAZENDA'
export const INATIVAR_CAD_FAZENDA = 'INATIVAR_CAD_FAZENDA'
export const DELETE_CAD_FAZENDA = 'DELETE_CAD_FAZENDA'
export const ATUALIZA_PROD_LIST = 'ATUALIZA_PROD_LIST'
export const LOAD_UNIDADES_NEGOCIO = 'LOAD_UNIDADES_NEGOCIO'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    produtores: null,
    unidades: null,
    selecaoProdutor: null,
    loading: true,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CHANGE_STATUS] (state, { index, indexFaz, status }) {
    state.produtores[index].fazendas[indexFaz].cadastro.ativo = status
  },
  [DELETE_CAD] (state, { index, indexFaz }) {
    state.produtores[index].fazendas.splice(indexFaz, 1)
  },
  [PUSH_CAD] (state, { index, obj }) {
    state.produtores[index].fazendas.push(obj)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_UNIDADES_NEGOCIO] ({ commit }) {
    commit(SET_STATE, { unidades: await api.listEntidade('unidadesNegocio') })
  },
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('produtor').then(
      data => {
        const produtores = data.map(({ cpf, cnpj, razao_social, nome, ...produtor }) => ({
          ...produtor,
          cpf_cnpj: cpf || cnpj,
          nome_razao_social: nome || razao_social,
        }))
        commit(SET_STATE, { produtores })
      },
    )
  },
  async [SET_PRODUTOR_SELECIONADO] ({ commit, dispatch }, selecaoProdutor) {
    commit(SET_STATE, { selecaoProdutor })
    await dispatch('dashboard/BOOTSTRAP_PRODUTOR', { id: selecaoProdutor }, { root: true })
  },
  async [BOOTSTRAP] ({ commit, dispatch }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [INATIVAR_PRODUTOR] ({ dispatch }, produtor) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir o Produtor?',
      icon: 'question',
      text: 'Ao excluí-lo o mesmo perderá o vinculo com as Fazendas e não poderá ser desfeito!',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('produtor', produtor.id).then(
          result => result,
          error => {
            Swal.fire({
              icon: 'error',
              title: error.message ? 'Erro!' : 'Ocorreu algum erro!',
              text: error.message
                ? error.message
                : 'Não foi possivel excluir o Produtor!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'O Produtor foi excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        dispatch(BOOTSTRAP)
      }
    })
  },
  async [DELETE_CAD_FAZENDA] ({ commit, state }, { id, produtorId, fazendaId }) {
    const index = state.produtores.findIndex(v => v.id === produtorId)
    const indexFaz = state.produtores[index].fazendas.findIndex(
      v => v.id === fazendaId,
    )
    Swal.fire({
      title: 'Tem certeza que deseja desvincular esse Produtor dessa Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Desvincular',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.deleteCadFaz(id, produtorId, fazendaId).then(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu algum erro!',
              text: 'Não foi possivel desvincular esse Produtor dessa Fazenda!',
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'O produtor foi desvinculado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          commit(DELETE_CAD, { index, indexFaz })
        }
      },
    )
  },
  async [INATIVAR_CAD_FAZENDA] ({ commit, state }, { id, produtorId, fazendaId }) {
    const index = state.produtores.findIndex(v =>
      v.id === produtorId,
    )
    const indexFaz = state.produtores[index].fazendas.findIndex(v =>
      v.id === fazendaId,
    )
    Swal.fire({
      title: 'Tem certeza que deseja inativar a relação desse Produtor com essa Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Inativar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarCadFaz(id, produtorId, fazendaId).then(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu algum erro!',
              text: 'Não foi possivel inativar a relação desse Produtor com essa Fazenda!',
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'A relação foi inativada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          commit(CHANGE_STATUS, { index, indexFaz, status: false })
        }
      },
    )
  },
  async [ATIVAR_CAD_FAZENDA] ({ commit, state }, { id, produtorId, fazendaId }) {
    const index = state.produtores.findIndex(v =>
      v.id === produtorId,
    )
    const indexFaz = state.produtores[index].fazendas.findIndex(v =>
      v.id === fazendaId,
    )
    Swal.fire({
      title: 'Tem certeza que deseja ativar a relação desse Produtor com essa Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ativar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.ativarCadFaz(id, produtorId, fazendaId).then(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu algum erro!',
              text: 'Não foi possivel ativar a relação desse Produtor com essa Fazenda!',
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'A relação foi ativada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          commit(CHANGE_STATUS, { index, indexFaz, status: true })
        }
      },
    )
  },
  async [ATUALIZA_PROD_LIST] ({ commit, state }, data) {
    const index = state.produtores.findIndex(
      v => v.id === data.produtorFazenda.produtor_id,
    )
    const obj = {
      cadastro: {
        cod_produtor_cliente: data.produtorFazenda.cod_produtor_cliente,
        cod_produtor_fornecedor: data.produtorFazenda.cod_produtor_fornecedor,
        id: data.produtorFazenda.id,
        ativo: data.produtorFazenda.ativo,
      },
      cnpj: data.produtorFazenda.fazenda.cnpj,
      id: data.produtorFazenda.fazenda.id,
      razao_social: data.produtorFazenda.fazenda.pessoa_juridica.razao_social,
      inscricao_estadual: data.produtorFazenda.fazenda.pessoa_juridica.inscricao_estadual,
      unidade_negocio: {
        filial:
          data.produtorFazenda.fazenda.unidade_negocio.pessoa_juridica
            .razao_social,
      },
    }
    commit(PUSH_CAD, { index, obj })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

import * as XLSX from 'xlsx'
import { v4 } from 'uuid'
import { getProperty } from './objectUtils'

export class Worksheet {
  constructor(data, template) {
    let workbook = new Uint8Array(data)
    workbook = XLSX.read(workbook, { type: 'array' })

    const worksheet = workbook.Sheets[workbook.SheetNames[0]]

    this.raw_data = XLSX.utils.sheet_to_json(worksheet, { raw: false }).map(
      row => Object.fromEntries(
        Object.entries(row).map(([key, value]) => ([key, value]))
      )
    )

    if (template) {
      this.data = this.raw_data.map(row => Object.fromEntries(
        template.listFields.map(field => [field.value, { value: row[field.map], err: false }])
      ))

      this.data.forEach(row => Object.assign(row, { id: v4() }))
    }
    this.header = this._parseHeader(worksheet)
  }

  _parseHeader (data) {
    const nColumns = XLSX.utils.decode_range(data['!ref']).e.c + 1
    const header = []
    for (let i = 0; i < nColumns; i++) {
      try {
        header.push(data[`${XLSX.utils.encode_col(i)}1`].v)
      } catch (_err) {
      }
    }
    return header
  }

  get columns () {
    return this.header
  }
}

export class JsonToExcel {
  constructor(data, headers, filename = 'Relatório.xlsx', sheetname = 'Relatório') {
    try {
      var rows = []
      data.map(item => {
        var row = {}
        headers.map(header => {
          const hv = getProperty(item, header.value)
          if (hv !== '') {
            row[header.text] = hv
          }
        })
        rows.push(row)
      })

      var ws = XLSX.utils.json_to_sheet(rows)

      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, sheetname)

      XLSX.writeFile(wb, filename)
    } catch (e) {
      console.log(e)
    }
  }
}

import { validarCNPJ, validarCPF, validarData, isNotFuture as iNF } from './validacoes'
import { numeric as isNumeric, decimal as isDecimal } from 'vuelidate/lib/validators'
import { isNullOrUndefined } from '../utils/commons'

const required = v => !!v || '* Obrigatório'
const minLength = (min, msg) => v => (!v || v.length >= min) || (msg || '* Inválido')
const maxLength = (max, msg) => v => (!v || v.length <= max) || (msg || '* Inválido')
const email = v => (!v || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(v)) || 'E-mail inválido'
const cpf = v => validarCPF(v) || '* Inválido'
const cnpj = v => (!v || validarCNPJ(v) || '* Inválido')
const validDate = v => (!v || validarData(v) || '* Inválido')
const isNotFuture = v => (!v || !iNF(v) || '* A data não deve ser no futuro')
const match = (field, msg) => v => !field || (v === field) || (msg || 'Os campos são diferentes')
const numeric = v => (!v || isNumeric(v) || 'Valor deve ser um numérico')
const decimal = v => (isDecimal(v) || 'Valor deve ser um número decimal')
const geq = (min, msg) => v => (!v || v >= min || (msg || `Valor deve ser no mínimo ${min}`))
const gt = (min, msg) => v => (!v || v > min || (msg || `Valor deve ser maior que ${min}`))
const requiredIf = (field, msg) => v => !field || (!!v || (msg || '* Obrigatório'))
const distinct = (data, msg) => v => (!v || data.filter(d => v === d).length < 2 || (msg || 'O valor deve ser distinto'))
const minMaxValue = (min, max) => v => {
  if (!isNullOrUndefined(min) && parseFloat(v) < min) {
    return `O valor deve ser maior ou igual a ${min.toFixed(2)}`
  } else if (!isNullOrUndefined(max) && max !== 0 && parseFloat(v) > max) {
    return `O valor deve ser menor ou igual a ${parseFloat(max).toFixed(2)}`
  }
  return true
}

export default {
  required,
  minLength,
  maxLength,
  email,
  cpf,
  cnpj,
  validDate,
  isNotFuture,
  match,
  numeric,
  decimal,
  geq,
  gt,
  requiredIf,
  distinct,
  minMaxValue
}

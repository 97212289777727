<template>
  <v-row
    class="pa-3 ma-0"
    align="center"
    justify="center"
  >
    <v-date-picker
      :value="date"
      :max="moment().toISOString(true).substr(0, 10)"
      class="ma-4"
      color="teal"
      locale="pt-br"
      @change="changeDate"
    />
    <v-time-picker
      :value="hours"
      format="24hr"
      class="ma-4"
      color="teal"
      scrollable
      @change="changeHours"
    />
  </v-row>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        date: null,
        hours: null,
      }
    },
    watch: {
      value: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.date = this.moment(newValue).toISOString(true).substr(0, 10)
          this.hours = this.moment(newValue).format('HH:mm')
        }
      },
    },
    mounted () {
      this.date = this.moment(this.value).toISOString(true).substr(0, 10)
      this.hours = this.moment(this.value).format('HH:mm')
    },
    methods: {
      changeDate (newValue) {
        this.$emit('input', `${newValue} ${this.hours}:00`)
      },
      changeHours (newValue) {
        this.$emit('input', `${this.date} ${newValue}:00`)
      },
    },
  }
</script>

import { updateField } from 'vuex-map-fields'
export const SET_SNACKBAR = 'SET_SNACKBAR'

export default {
  updateField,
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_USER (state, payload) {
    state.user = payload
  },
  SET_SNACKBAR (state, payload) {
    state.snackbar = payload
  },
}

import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const DELETE_UNIDADE = 'DELETE_UNIDADE'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    unidadesNegocio: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ commit }) {
    await api.listEntidade('unidadeNegocio').then(
      unidadesNegocio => {
        commit(SET_STATE, { unidadesNegocio })
      },
    )
  },
  async [DELETE_UNIDADE] ({ dispatch, commit, state }, unidadeNegocio) {
    const index = state.unidadesNegocio.findIndex(c => c.id === unidadeNegocio.id)

    Swal.fire({
      title: 'Tem certeza que deseja excluir a Unidade de Negócio?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('unidadeNegocio', unidadeNegocio.id)
          .then(
            result => result,
            () => {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu algum erro!',
                text: 'Não foi possivel excluir a Unidade de Negócio!',
              })
            },
          )
      },
    }).then(result => {
      if (result.isConfirmed) {
        window.Toast.fire('A Unidade de Negócio foi excluído com sucesso!', '', 'success')
        commit('SET_STATE', { unidadesNegocio: state.unidadesNegocio.splice(index, 1) })
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

<template>
  <v-list-group
    dense
    :prepend-icon="subGroup ? 'mdi-chevron-down' : item.icon"
    :sub-group="subGroup"
    append-icon="mdi-menu-down"
    :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'primary' : 'grey darken-1'"
  >
    <template v-slot:activator>
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        small
      >
        <v-icon
          v-if="subGroup"
          v-text="item.icon"
        />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
      />

      <base-item
        v-else
        :key="`item-${i}`"
        :item="child"
        :class="subGroup ? 'subgroup' : 'group'"
      />
    </template>
  </v-list-group>
</template>

<script>
  // Utilities
  import kebabCase from 'lodash/kebabCase'
  import { mapState } from 'vuex'

  export default {
    name: 'ItemGroup',

    inheritAttrs: false,

    model: { prop: 'value', event: 'set-value' },

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          children: []
        })
      },
      subGroup: {
        type: Boolean,
        default: false
      },
      text: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState(['barColor']),
      children () {
        return this.item.children
      },
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      group () {
        return this.genGroup(this.item.children)
      }
    },

    methods: {
      genGroup (children) {
        return children
          .filter(item => item.to)
          .map(item => {
            const parent = item.group || this.item.group
            let group = `${parent}/${kebabCase(item.to)}`

            if (item.children) {
              group = `${group}|${this.genGroup(item.children)}`
            }

            return group
          }).join('|')
      }
    }
  }
</script>

<style lang="scss" scoped>
.v-list-group__activator p {
  margin-bottom: 0;
}

.v-list .v-list-group .v-list-group__header .v-list-item__content .v-list-item__title,
.v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 14px !important;
}

.v-list-group__items .v-list-group--sub-group .v-list-group__header .v-list-item__icon--text, .v-list-group__items .v-list-item .v-list-item__icon {
  margin-top: 7px !important;
}

.v-list-item__icon--text, .v-list-item__icon:first-child, .v-list-item__icon--text, .v-list-item__icon:first-child {
    margin-right:  7px !important;
}

.v-list--dense .v-list-item__icon--text, .v-list--dense .v-list-item__icon:first-child {
    margin-top: 5px;
}

.v-list-item__action, .v-list-item__avatar, .v-list-item__icon, .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 21px;
}

.subgroup {
    margin-left: 30px !important;
}

.group {
    margin-left: 15px !important;
}

.active {
  color: #ffffff !important;
}
</style>

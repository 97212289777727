<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    class="ma-0"
    persistent
  >
    <v-card class="pa-2">
      <v-row
        justify="center"
        class="ma-0 my-4"
      >
        <v-icon
          style="font-size: 100px"
          color="error"
        >
          mdi-close-circle-outline
        </v-icon>
      </v-row>
      <div v-if="!error">
        <h2 style="text-align: center; color: #343434">
          Tem certeza que deseja excluir?
        </h2>
        <h3 style="text-align: center; color: #343434">
          {{ female ? 'A' : 'O' }} {{ label }} será removid{{ female ? 'a' : 'o' }} do sistema.
        </h3>
        <v-row
          class="ma-0 py-4"
          justify="center"
        >
          <v-btn
            color="#dedede"
            class="mx-2"
            :disabled="deleting"
            @click="$emit('closeDelete')"
          >
            <v-icon
              dark
              left
              class="pr-2"
            >
              mdi-backup-restore
            </v-icon>
            Não
          </v-btn>
          <v-btn
            color="error"
            class="mx-2"
            :loading="deleting"
            @click="deleteItem"
          >
            <v-icon
              dark
              left
              class="pr-2"
            >
              mdi-minus-circle
            </v-icon>
            Sim, remover!
          </v-btn>
        </v-row>
      </div>
      <div v-else>
        <h2 style="text-align: center; color: #343434">
          Erro
        </h2>
        <p
          v-for="(item, index) in errors"
          :key="index"
          style="font-weight: 500; text-align: center"
        >
          {{ item }}
        </p>
        <v-row
          class="ma-0 py-4"
          justify="center"
        >
          <v-btn
            color="primary"
            @click="$emit('closeDelete')"
          >
            OK
          </v-btn>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DeleteDialog',
    props: {
      entity: {
        type: String,
        default: '',
      },
      itemId: {
        type: [String, Number],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      show: {
        type: Boolean,
        default: false,
      },
      female: {
        type: Boolean,
        default: false,
      },
      callback: {
        type: Function,
        default: () => null,
      },
    },
    data () {
      return {
        deleting: false,
        error: false,
        errors: [],
      }
    },
    watch: {
      show: function (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.deleting = false
          this.error = false
          this.errors = []
        }
      },
    },
    methods: {
      deleteItem () {
        this.deleting = true
        this.api.delete(this.entity, this.itemId).then(response => {
          this.callback()
          this.$emit('closeDelete')
          this.Swal.fire(
            'Sucesso!',
            `${this.female ? 'A' : 'O'} ${this.label} foi removid${this.female ? 'a' : 'o'}.`,
            'success',
          )
        }, error => {
          this.error = true
          this.errors = Array.isArray(error) ? error : [JSON.stringify(error)]
        })
      },
    },
  }
</script>

<template>
  <crud-card title="Dados">
    <div>
      <v-row class="pa-0">
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="form.nomeFantasia"
            dense
            autocomplete="nope"
            class="no-auto-complete"
            :rules="[...(optional.nomeFantasia ? [] : [rules.required] )]"
            :label="`Nome Fantasia${optional.nomeFantasia ? '' : '*'}`"
            validate-on-blur
            @change="$emit('change', form)"
          />
        </v-col>
        <v-col
          v-if="!exclude.email"
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="form.email"
            dense
            autocomplete="nope"
            :rules="[...(optional.email ? [] : [rules.required] ), rules.email ]"
            :label="`Email${optional.email ? '' : '*'}`"
            validate-on-blur
            @change="$emit('change', form)"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="form.razaoSocial"
            dense
            autocomplete="nope"
            :rules="[...(optional.razaoSocial ? [] : [rules.required] )]"
            :label="`Razão Social${optional.razaoSocial ? '' : '*'}`"
            validate-on-blur
            @change="$emit('change', form)"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="form.cnpj"
            v-mask="maskCnpj"
            dense
            autocomplete="nope"
            :rules="[...(optional.cnpj ? [] : [rules.required] ), rules.cnpj ]"
            :label="`CNPJ${optional.cnpj ? '' : '*'}`"
            validate-on-blur
            @change="$emit('change', form)"
          />
        </v-col>
        <slot />
      </v-row>
    </div>
  </crud-card>
</template>
<script>
  import rules from '../../utils/formRules'
  export default {
    name: 'PessoaJuridica',
    props: {
      optional: {
        type: Object,
        default: () => { return {} },
      },
      exclude: {
        type: Array,
        default: () => { return [] },
      },
      notEditable: {
        type: Array,
        default: () => { return [] },
      },
      form: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        valid: true,
        rules: rules,
        maskCnpj: '##.###.###/####-##',
      }
    },
  }
</script>

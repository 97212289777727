<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    active-class="white primary--text"
  >
    <v-list-item-icon
      v-if="text"
      class="v-list-item__icon--text"
      dense
      v-text="computedText"
    />

    <v-list-item-icon
      v-if="item.icon"
      dense
    >
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content
      v-if="item.title || item.subtitle"
      dense
    >
      <v-list-item-title
        dense
        v-text="item.title"
      />

      <v-list-item-subtitle
        dense
        v-text="item.subtitle"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'

  export default {
    name: 'Item',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined
        })
      },
      text: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

        return text
      },
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      }
    }
  }
</script>
<style lang="scss" scoped>
.v-list-group__activator p {
  margin-bottom: 0;
}

.v-list .v-list-group .v-list-group__header .v-list-item__content .v-list-item__title,
.v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 14px !important;
}

.v-list-item__icon--text, .v-list-item__icon:first-child, .v-list-item__icon--text, .v-list-item__icon:first-child {
    margin-right:  7px !important;
}

.v-list-group__items .v-list-group--sub-group .v-list-group__header .v-list-item__icon--text, .v-list-group__items .v-list-item .v-list-item__icon {
  margin-top: 7px !important;
}

.v-list--dense .v-list-item__icon--text, .v-list--dense .v-list-item__icon:first-child {
    margin-top: 5px;
}

.v-list-item__action, .v-list-item__avatar, .v-list-item__icon, .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 21px;
}

.active {
  color: #ffffff !important;
}
</style>

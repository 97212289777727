<template>
  <v-row>
    <v-col
      v-if="withCurrent"
      cols="12"
      :md="stackedLayout? 12: 4"
    >
      <v-text-field
        v-model="currentPassword"
        :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showCurrent ? 'text' : 'password'"
        label="Senha atual"
        :rules="rulesCurrentPassword"
        validate-on-blur
        autocomplete="new-password"
        @click:append="showCurrent = !showCurrent"
      />
    </v-col>
    <v-col
      cols="12"
      :md="stackedLayout? 12: 4"
    >
      <v-text-field
        v-model="newPassword"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rulesNewPassword"
        :type="show ? 'text' : 'password'"
        :label="labelSenha"
        validate-on-blur
        autocomplete="new-password"
        @click:append="show = !show"
      />
    </v-col>

    <v-col
      v-if="withConfirmation"
      cols="12"
      :md="stackedLayout? 12: 4"
    >
      <v-text-field
        v-model="passwordConfirm"
        :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rulesPasswordConfirm"
        :type="showConfirmation ? 'text' : 'password'"
        label="Confirmação de senha"
        validate-on-blur
        autocomplete="new-password"
        @click:append="showConfirmation = !showConfirmation"
      />
    </v-col>
  </v-row>
</template>
<script>
  import rules from '../../utils/formRules'
  export default {
    name: 'Password',
    props: {
      minLength: {
        type: Number,
        default: () => 8,
      },
      withConfirmation: {
        type: Boolean,
        default: true,
      },
      withCurrent: {
        type: Boolean,
        default: false,
      },
      storeModule: {
        type: String,
        default: 'form',
      },
      labelSenha: {
        type: String,
        default: 'Senha',
      },
      stackedLayout: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        show: false,
        showConfirmation: false,
        showCurrent: false,
        name: null,
        valid: true,
        rules: rules,
      }
    },
    computed: {
      rulesCurrentPassword () {
        return [
          ...(this.withCurrent
            ? [
              rules.requiredIf(this.newPassword, '* Por favor, informe a senha atual'),
              rules.requiredIf(this.passwordConfirm, '* Por favor, informe a senha atual'),
            ]
            : []),
        ]
      },
      rulesNewPassword () {
        return [
          rules.minLength(this.minLength, `Mínimo ${this.minLength} caracteres.`),
          rules.match(this.passwordConfirm, 'As senhas são diferentes.'),
          ...(this.withCurrent
            ? [rules.requiredIf(this.currentPassword, 'Por favor, informe a nova senha')]
            : []),
        ]
      },
      rulesPasswordConfirm () {
        return [
          rules.minLength(this.minLength, `Mínimo ${this.minLength} caracteres.`),
          rules.match(this.newPassword, 'As senhas são diferentes.'),
          ...(this.withCurrent
            ? [rules.requiredIf(this.currentPassword, 'Por favor, informe a nova senha')]
            : []),
        ]
      },
    },
    methods: {
    },
  }
</script>

<template>
  <v-card
    class="px-6"
    outlined
    style="margin-top: 14px; border-radius: 16px"
  >
    <slot name="header" />
    <v-card-title
      v-if="title"
      class="pb-2 px-0"
      style="text-shadow: grey 0.1em 0.1em 0.2em;"
    >
      {{ title }}
    </v-card-title>
    <slot />
  </v-card>
</template>
<script>
  export default {
    name: 'Card',
    props: {
      title: {
        type: String,
        default: () => ''
      }
    }
  }
</script>

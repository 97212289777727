import Vue from 'vue'
import { axios } from '../api/axios'

const load = () => {
  return new Promise(resolve => {
    axios.post('auth/me').then(response => {
      if (response) {
        response.data.role = response.data.roles[0].name
        Vue.prototype.$user.set(response.data)
      } else {
        Vue.prototype.$user.set({ role: 'guest' })
      }
      resolve()
    }).catch(_err => {
      Vue.prototype.$user.set({ role: 'guest' })
      resolve()
    })
  })
}

const logout = () => {
  return new Promise(resolve => {
    const forceLogout = () => {
      Vue.prototype.$user.set({ role: 'guest' })
      localStorage.removeItem('user-token')
    }
    axios.post('auth/logout').then(response => {
      forceLogout()
      resolve()
    }).catch(_err => {
      forceLogout()
      resolve()
    })
  })
}

const login = (user, token) => {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  localStorage.setItem('user-token', token)
  user.role = user.roles[0].name
  Vue.prototype.$user.set(user)
}

export { load, login, logout }

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="moment(value).format('DD/MM/YYYY')"
        :label="label"
        color="white"
        readonly
        dense
        outlined
        dark
        hide-details
        v-on="on"
      />
    </template>
    <v-date-picker
      :value="value"
      style="margin: 0px"
      :max="max"
      :min="min"
      color="teal"
      locale="pt-br"
      @input="(date) => {
        menu = false
        $emit('input', date)
      }"
    />
  </v-menu>
</template>

<script>
  import moment from '../../plugins/moment'

  export default {
    props: {
      value: {
        type: String,
        default: moment().toISOString(true).substr(0, 10),
      },
      min: {
        type: String,
        default: undefined,
      },
      max: {
        type: String,
        default: moment().toISOString(true).substr(0, 10),
      },
      label: {
        type: String,
        default: 'Data',
      },
    },
    data () {
      return {
        menu: false,
      }
    },
  }
</script>

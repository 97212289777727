import Vue from 'vue'
import Router from 'vue-router'
import { axios, cancelSource } from '../api/axios'

Vue.use(Router)

const roles = ['admin', 'coordenador', 'especialista', 'gestor', 'supervisor', 'tecnico']

const restrictRoutes = [
  {
    path: 'cadastro/fazenda',
    component: () => import('@/views/fazenda/FazendaForm'),
    meta: { title: 'Cadastrando Fazendas' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'alterar/fazenda',
    component: () => import('@/views/fazenda/FazendaForm'),
    meta: { title: 'Editando Fazendas' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'cadastro/produtor',
    component: () => import('@/views/produtor/ProdutorForm'),
    meta: { title: 'Cadastrando Produtor' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'alterar/produtor',
    component: () => import('@/views/produtor/ProdutorForm'),
    meta: { title: 'Editando Produtor' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'consultores',
    component: () => import('@/views/consultor/ConsultorList'),
    meta: { title: 'Manutenção de Consultores' },
    roles: ['admin', 'coordenador', 'especialista', 'gestor', 'supervisor'],
  },
  {
    path: 'cadastro/consultor',
    component: () => import('@/views/consultor/ConsultorForm'),
    meta: { title: 'Cadastrando Consultores' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'alterar/consultor',
    component: () => import('@/views/consultor/ConsultorForm'),
    meta: { title: 'Editando Consultores' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'supervisores',
    component: () => import('@/views/supervisor/SupervisorList'),
    meta: { title: 'Manutenção de Supervisores' },
    roles: ['admin', 'coordenador', 'especialista', 'gestor'],
  },
  {
    path: 'cadastro/supervisor',
    component: () => import('@/views/supervisor/SupervisorForm'),
    meta: { title: 'Cadastrando Supervisor' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'alterar/supervisor',
    component: () => import('@/views/supervisor/SupervisorForm'),
    meta: { title: 'Editando Supervisor' },
    roles: ['admin', 'coordenador', 'gestor'],
  },
  {
    path: 'gestores',
    component: () => import('@/views/gestor/GestorList'),
    meta: { title: 'Manutenção de Gerentes' },
    roles: ['admin', 'coordenador', 'especialista'],
  },
  {
    path: 'cadastro/gestor',
    component: () => import('@/views/gestor/GestorForm'),
    meta: { title: 'Cadastrando Gerente' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'alterar/gestor',
    component: () => import('@/views/gestor/GestorForm'),
    meta: { title: 'Editando Gerente' },
    roles: ['admin', 'coordenador'],
  },

  {
    path: 'especialistas',
    component: () => import('@/views/especialista/EspecialistaList'),
    meta: { title: 'Manutenção de Especialistas' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'cadastro/especialista',
    component: () => import('@/views/especialista/EspecialistaForm'),
    meta: { title: 'Cadastrando Especialista' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'alterar/especialista',
    component: () => import('@/views/especialista/EspecialistaForm'),
    meta: { title: 'Editando Especialista' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'coordenadores',
    component: () => import('@/views/coordenador/CoordenadorList'),
    meta: { title: 'Manutenção de Coordenadores' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'cadastro/coordenador',
    component: () => import('@/views/coordenador/CoordenadorForm'),
    meta: { title: 'Cadastrando Coordenador' },
    roles: ['admin'],
  },
  {
    path: 'alterar/coordenador',
    component: () => import('@/views/coordenador/CoordenadorForm'),
    meta: { title: 'Editando Coordenador' },
    roles: ['admin'],
  },
  {
    path: 'unidadenegocio',
    component: () => import('@/views/UnidadeNegocio/UnidadeNegocioList'),
    meta: { title: 'Manutenção de Unidades de Negócio' },
    roles: ['admin', 'coordenador', 'especialista', 'gestor', 'supervisor'],
  },
  {
    path: 'cadastro/unidadenegocio',
    component: () => import('@/views/UnidadeNegocio/UnidadeNegocioForm'),
    meta: { title: 'Cadastrando Unidade de Negócio' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'alterar/unidadenegocio',
    component: () => import('@/views/UnidadeNegocio/UnidadeNegocioForm'),
    meta: { title: 'Editando Unidade de Negócio' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'cadastro/projeto',
    component: () => import('@/views/projeto/ProjetoForm'),
    meta: { title: 'Cadastrando Projeto' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'alterar/projeto',
    component: () => import('@/views/projeto/ProjetoForm'),
    meta: { title: 'Editando Projeto' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'cadastro/regional',
    component: () => import('@/views/regional/RegionalForm'),
    meta: { title: 'Cadastrando Regional' },
    roles: ['admin', 'coordenador'],
  },
  {
    path: 'alterar/regional',
    component: () => import('@/views/regional/RegionalForm'),
    meta: { title: 'Editando Regional' },
    roles: ['admin', 'coordenador'],
  },
]

const getRestrictRoutes = (role, permissions) => {
  return restrictRoutes.filter(route => route.roles.includes(role)).map(route => {
    return {
      ...route,
      meta: { permissions, ...route.meta },
    }
  })
}

const permissionsFactory = (papel, guest = true) => {
  const permissions = []
  roles.forEach(role => {
    if (role !== papel) {
      permissions.push({
        role,
        access: false,
        redirect: role,
      })
    }
  })

  if (guest) {
    permissions.push({
      role: 'guest',
      access: false,
      redirect: 'login',
    })
  }

  return permissions
}

const routerFactory = () => {
  return roles.map(role => {
    const permissions = permissionsFactory(role)
    return {
      name: role,
      path: `/${role}`,
      redirect: `/${role}/sustainable/dashboard`,
      component: () => import('@/views/Index'),
      meta: { permissions },
      children: [
        // Rotas que todos podem ver
        {
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: { permissions, title: 'Dashboard Padrão' },
        },
        {
          path: 'sustainable/dashboard',
          component: () => import('@/views/sustainable-dashboard/Dashboard'),
          meta: { permissions, title: 'Dashboard de Sustentabilidade' },
        },
        {
          path: 'diagnosticos',
          component: () => import('@/views/diagnosticos/Diagnosticos'),
          meta: { permissions, title: 'Manutenção dos Diagnósticos Padrão' },
        },
        {
          path: 'sustainable/diagnosticos',
          component: () => import('@/views/sustainable-diagnosticos/Diagnosticos'),
          meta: { permissions, title: 'Manutenção dos Diagnósticos de Sustentabilidade' },
        },
        {
          path: 'produtores',
          component: () => import('@/views/produtor/ProdutorList'),
          meta: { permissions, title: 'Manutenção de Produtores' },
        },
        {
          path: 'fazendas',
          component: () => import('@/views/fazenda/FazendaList'),
          meta: { permissions, title: 'Manutenção de Fazendas' },
        },
        {
          path: 'projetos',
          component: () => import('@/views/projeto/ProjetoList'),
          meta: { permissions, title: 'Manutenção de Projetos' },
        },
        {
          path: 'regionais',
          component: () => import('@/views/regional/RegionalList'),
          meta: { permissions, title: 'Manutenção de Regionais' },
        },
      ].concat(getRestrictRoutes(role, permissions)),
    }
  })
}

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    ...routerFactory(),
    /* LOGIN */
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/login/Login'),
      meta: {
        permissions: permissionsFactory(null, false),
      },
    },
    /* LANDPAGE */
    {
      name: 'land',
      path: '/',
      meta: {
        permissions: permissionsFactory(null),
      },
    },
    /* REDIRECIONAR ROTAS NÃO EXISTENTES PARA PÁGINA INICIAL */
    {
      path: '*',
      redirect: '/',
    },
    {
      name: 'verify-mail',
      path: '/verify-mail/:change/:token',
      component: () => import('@/views/mail-confirmation/MailConfirmation'),
      meta: {
        permissions: permissionsFactory(null, false),
      },
    },
    {
      name: 'reset-password',
      path: '/reset-password/:token',
      component: () => import('@/views/reset-password/ResetPassword'),
      meta: {
        permissions: permissionsFactory(null, false),
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  cancelSource.source.cancel('Canceled requests in previous route.')
  cancelSource.source = axios.CancelToken.source() // generate new token
  next()
})

export default router

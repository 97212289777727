import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './states'
import form from '@/store/modules/form'
import regionais from '@/store/modules/regionais'
import gestores from '@/store/modules/gestores'
import unidadesNegocio from '@/store/modules/unidadesNegocio'
import coordenadores from '@/store/modules/coordenadores'
import especialistas from '@/store/modules/especialistas'
import supervisores from '@/store/modules/supervisores'
import consultores from '@/store/modules/consultores'
import produtores from '@/store/modules/produtores'
import fazendas from '@/store/modules/fazendas'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    form,
    regionais,
    gestores,
    unidadesNegocio,
    coordenadores,
    especialistas,
    supervisores,
    consultores,
    produtores,
    fazendas,
  },
})

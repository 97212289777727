import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const PUSH_CAD = 'PUSH_CAD'
export const LOAD_FAZENDAS = 'LOAD_FAZENDAS'
export const ATT_FAZENDAS = 'ATT_FAZENDAS'
export const DELETE_CAD_FAZENDA = 'DELETE_CAD_FAZENDA'
export const INATIVAR_CAD_FAZENDA = 'INATIVAR_CAD_FAZENDA'
export const ATIVAR_CAD_FAZENDA = 'ATIVAR_CAD_FAZENDA'
export const ADD_CARD_CADASTRO = 'ADD_CARD_CADASTRO'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const DELETE_CAD = 'DELETE_CAD'
export const INATIVAR_FAZENDA = 'INATIVAR_FAZENDA'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    fazendas: null,
    fazendasProdutor: [],
    loading: true,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CHANGE_STATUS] (state, { index, indexFaz, status }) {
    state.fazendas[index].produtores[indexFaz].cadastro.ativo = status
  },
  [PUSH_CAD] (state, { index, obj }) {
    state.fazendas[index].produtores.push(obj)
  },
  [DELETE_CAD] (state, { index, indexFaz }) {
    state.fazendas[index].produtores.splice(indexFaz, 1)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_FAZENDAS] ({ commit }) {
    await api.listEntidade('fazenda').then(fazendas => {
      commit(SET_STATE, { fazendas })
    })
  },
  async [ATT_FAZENDAS] ({ state, commit }, id) {
    const fazendasProdutor = state.fazendas.filter(el => el.produtores.find(prod => prod.id === id) && el.produtores.find(prod => prod.id === id).cadastro.ativo === 1)
    commit(SET_STATE, { fazendasProdutor })
  },
  async [BOOTSTRAP] ({ commit, dispatch }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_FAZENDAS)
    commit(SET_STATE, { loading: false })
  },
  async [DELETE_CAD_FAZENDA] ({ commit, state }, { id, produtorId, fazendaId }) {
    const index = state.fazendas.findIndex(v => v.id === fazendaId)
    const indexFaz = state.fazendas[index].produtores.findIndex(
      v => v.id === produtorId,
    )
    Swal.fire({
      title: 'Tem certeza que deseja desvincular esse Produtor dessa Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Desvincular',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.deleteCadFaz(id, produtorId, fazendaId).then(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu algum erro!',
              text: 'Não foi possivel desvincular esse Produtor dessa Fazenda!',
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'O Produtor foi desvinculado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          commit(DELETE_CAD, { index, indexFaz })
        }
      },
    )
  },
  async [INATIVAR_CAD_FAZENDA] ({ commit, state }, { id, produtorId, fazendaId }) {
    const index = state.fazendas.findIndex(v =>
      v.id === fazendaId,
    )
    const indexFaz = state.fazendas[index].produtores.findIndex(v =>
      v.id === produtorId,
    )
    Swal.fire({
      title: 'Tem certeza que deseja inativar a relação desse Produtor com essa Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Inativar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarCadFaz(id, produtorId, fazendaId).then(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu algum erro!',
              text: 'Não foi possivel inativar a relação desse Produtor com essa Fazenda!',
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'A relação foi inativada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          commit(CHANGE_STATUS, { index, indexFaz, status: false })
        }
      },
    )
  },
  async [ATIVAR_CAD_FAZENDA] ({ commit, state }, { id, produtorId, fazendaId }) {
    const index = state.fazendas.findIndex(v =>
      v.id === fazendaId,
    )
    const indexFaz = state.fazendas[index].produtores.findIndex(v =>
      v.id === produtorId,
    )
    Swal.fire({
      title: 'Tem certeza que deseja ativar a relação desse Produtor com essa Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ativar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.ativarCadFaz(id, produtorId, fazendaId).then(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu algum erro!',
              text: 'Não foi possivel ativar a relação desse Produtor com essa Fazenda!',
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'A relação foi ativada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          commit(CHANGE_STATUS, { index, indexFaz, status: true })
        }
      },
    )
  },
  async [ADD_CARD_CADASTRO] ({ commit, state }, data) {
    const index = state.fazendas.findIndex(
      v => v.id === data.produtorFazenda.fazenda_id,
    )

    const { nome, cpf, cnpj, razao_social } = data.produtorFazenda.produtor.pessoa.pessoa_tipo

    const obj = {
      cadastro: {
        cod_produtor_cliente: data.produtorFazenda.cod_produtor_cliente,
        cod_produtor_fornecedor: data.produtorFazenda.cod_produtor_fornecedor,
        id: data.produtorFazenda.id,
        ativo: data.produtorFazenda.ativo,
      },
      id: data.produtorFazenda.produtor.id,
      ...(cpf ? { nome, cpf } : { razao_social, cnpj }),
    }
    commit(PUSH_CAD, { index, obj })
  },
  async [INATIVAR_FAZENDA] ({ dispatch }, fazenda) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a Fazenda?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('fazenda', fazenda.id).then(
          result => result,
          error => {
            Swal.fire({
              icon: 'error',
              title: error.message ? 'Erro!' : 'Ocorreu algum erro!',
              text: error.message ? error.message : 'Não foi possivel excluir a Fazenda!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'A Fazenda foi excluída com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

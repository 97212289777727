<template>
  <v-data-table
    :key="anIncrementoNumber"
    v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
    :headers="headerList"
    :items="valueFiltered"
    :search="search"
    :height="TableHeigth"
    no-results-text="Nenhum resultado para o texto pesquisado"
    :single-expand="singleExpand"
    :show-expand="showExpand"
    :expanded.sync="expanded"
    item-key="id"
    calculate-widths
    fixed-header
    :style="stylesTable"
    :class="tableClass"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :group-by="groupHeader"
    :footer-props="{
      showFirstLastPage: false,
      showCurrentPage: true,
      showCurrentPageSize: true,
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      'items-per-page-options': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? [20, 40, 60, 120, -1] : $vuetify.breakpoint.sm || $vuetify.breakpoint.md ? [7, 14, 38, 68, 100, -1] : [1]
    }"
  >
    <template v-slot:top>
      <v-row
        v-if="showSearch || $slots.datas || exportXlsx || $slots.btnImporte || $slots.btnCadastro"
        class="pt-2 px-5"
        style="padding: 18px;"
      >
        <v-col
          v-if="showSearch"
          cols="12"
          sm="12"
          md="7"
          lg="4"
          xl="6"
          style="padding: 0px;"
        >
          <v-text-field
            v-model="search"
            height="43"
            append-icon="mdi-magnify"
            color="primary"
            label="Pesquisar"
            outlined
            dense
            elevation="0"
            hide-details
            clearable
            clear-icon="mdi-close"
            class="ml-1 mt-1"
          />
        </v-col>
        <v-col
          v-if="$slots.datas"
          cols="12"
          sm="4"
          md="5"
          lg="auto"
          xl="auto"
        >
          <v-row>
            <slot name="datas" />
          </v-row>
        </v-col>
        <v-spacer />
        <v-col
          v-if="exportXlsx"
          cols="12"
          sm="4"
          md="auto"
          lg="auto"
          xl="auto"
        >
          <v-btn
            v-if="exportXlsx"
            block
            color="primary"
            depressed
            @click="exportExcel"
          >
            <v-icon
              dark
              left
            >
              mdi-file-excel
            </v-icon>
            {{ 'Exportar' }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
          md="auto"
          lg="auto"
          xl="auto"
        >
          <v-row>
            <slot
              v-if="$slots.btnCadastro"
              name="btnCadastro"
            />
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template
      v-for="(coluna,indexHeader) in headerName"
      v-slot:[`header.${indexHeader}`]="{ header }"
    >
      {{ header.text }}
      <v-btn
        v-if="headerName[indexHeader]"
        :key="`group-${indexHeader}`"
        icon
        x-small
        title="Agrupar os registros"
        @click="setGroupHeader(header.value)"
      >
        <v-icon
          small
        >
          mdi-lightbulb-group
        </v-icon>
      </v-btn>
      <v-menu
        v-if="filters[header.value]"
        :key="`filter-${indexHeader}`"
        offset-y
        :close-on-content-click="false"
        style="border-radius: 20px;"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            title="Filtrar os registros"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              v-if="filters[header.value].length"
              bottom
              color="red"
              dot
              offset-x="8"
              offset-y="8"
              overlap
            >
              <v-icon
                small
                color="red"
              >
                mdi-filter-variant-plus
              </v-icon>
            </v-badge>
            <v-icon
              v-else
              small
            >
              mdi-filter-variant
            </v-icon>
          </v-btn>
        </template>
        <div style="background-color: white; width: 350px">
          <v-list>
            <v-list-item>
              <v-autocomplete
                v-model="filters[header.value]"
                multiple
                dense
                auto-select-first
                clearable
                chips
                small-chips
                deletable-chips
                color="primary"
                :items="optionsFilter(header.value)"
                append-icon="mdi-filter"
                :label="`Informe o filtro por: ${header.text}:`"
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index < 4"
                    small
                    class="caption"
                  >
                    <span>
                      {{ item }}
                    </span>
                  </v-chip>
                  <span
                    v-if="index === 4"
                    class="grey--text caption"
                  >
                    (+{{ filters[header.value].length - 4 }} outros)
                  </span>
                </template>
              </v-autocomplete>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </template>
    <template v-slot:group.header="{ group, groupBy, items, isOpen, toggle, remove }">
      <td
        colspan="2"
      >
        <v-btn
          x-small
          icon
          :title="(isOpen ? 'Recolhe os itens grupo' : 'Expande os itens do grupo' )"
          @click="toggle"
        >
          <v-icon
            small
            :color="(isOpen ? 'red' : 'green' )"
          >
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
        </v-btn>
        <span style="font-size: 13px; font-weight: bold; text-align: center;color: #003677"> {{ groupNameHeader(groupBy) }}: </span>
        <span style="font-size: 13px; text-align: center; display: inline-block; background-color: #e0e0e0; border-radius: 10px;">{{ group }} ({{ items.length }})<br></span>
        <v-btn
          icon
          x-small
          title="Desfaz o agrupamento dos registros do grid."
          @click="() => { setGroupHeader(''), remove }"
        >
          <v-icon
            small
            color="red"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </td>
    </template>
    <template v-slot:expanded-item="{ item }">
      <slot
        name="expanded-item"
        :item="item"
        :headers="headerList"
      />
    </template>
    <template
      v-slot:[`item.actions`]="{ item }"
    >
      <div
        style="display: flex !important; flex-direction: row;"
      >
        <v-tooltip
          v-for="(action, index) in actions"
          :key="index"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="action.color"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
              @click="action.click(item)"
            >
              {{ action.icon }}
            </v-icon>
          </template>
          <span>{{ action.title }}</span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStop }} de {{ items.itemsLength }}
    </template>
    <template
      v-for="slot in slots"
      v-slot:[slot]="{ item }"
    >
      <slot
        :name="slot"
        :item="item"
      />
    </template>

    <template v-slot:no-data>
      <v-row justify="center">
        {{ noDataMsg }}
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { JsonToExcel } from '../../utils/Worksheet'
  import { getProperty } from '../../utils/objectUtils'
  import Sortable from 'sortablejs'
  import hash from 'object-hash'

  function watchClass (targetNode, classToWatch) {
    let lastClassState = targetNode.classList.contains(classToWatch)
    const observer = new MutationObserver((mutationsList) => {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i]
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const currentClassState = mutation.target.classList.contains(classToWatch)
          if (lastClassState !== currentClassState) {
            lastClassState = currentClassState
            if (!currentClassState) {
              mutation.target.classList.add('sortHandle')
            }
          }
        }
      }
    })
    observer.observe(targetNode, { attributes: true })
  }

  export default {
    name: 'List',
    directives: {
      'sortable-table': {
        inserted: (el, binding) => {
          el.querySelectorAll('th').forEach((draggableEl) => {
            watchClass(draggableEl, 'sortHandle')
            draggableEl.classList.add('sortHandle')
          })
          Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {})
        }
      }
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      itemKey: {
        type: String,
        default: 'id'
      },
      headers: {
        type: Array,
        default: () => []
      },
      actions: {
        type: Array,
        default: () => []
      },
      noDataMsg: {
        type: String,
        default: 'Nenhum item encontrado.'
      },
      slots: {
        type: Array,
        default: () => []
      },
      singleExpand: {
        type: Boolean,
        default: true
      },
      showExpand: {
        type: Boolean,
        default: false
      },
      stylesTable: {
        type: String,
        default: 'font-size: 10px'
      },
      tableClass: {
        type: String,
        default: 'elevation-1 mt-2 text-no-wrap'
      },
      sortBy: {
        type: Array,
        default: () => []
      },
      sortDesc: {
        type: Array,
        default: () => []
      },
      permission: {
        type: String,
        default: ''
      },
      exportXlsx: {
        type: Boolean,
        default: false
      },
      showSearch: {
        type: Boolean,
        default: true
      },
      itemHeight: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        search: null,
        expanded: [],
        excel: null,
        filterOpts: [],
        filters: '',
        anIncrementoNumber: 1,
        headerList: [],
        groupHeader: [],
        headerName: []
      }
    },
    computed: {
      ...mapGetters(['hasPermission']),
      valueFiltered () {
        return this.value.filter((d) => {
          return Object.keys(this.filters).every((f) => {
            return this.filters[f].length < 1 || this.filters[f].includes(getProperty(d, f))
          })
        })
      },
      TableHeigth () {
        return this.itemHeight ? this.itemHeight : this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.xxl ? 'calc(81vh - 90px)' : this.$vuetify.breakpoint.lg ? 'calc(80vh - 145px)' : 'calc(80vh - 135px)'
      },
      activeHeaders () {
        return this.headerList.filter(header => header.active)
      }
    },
    created () {
      const { nome } = this.$user.get()
      const headerSave = JSON.parse(localStorage.getItem(('CrudList-' + hash(this.headers) + '-' + nome)))

      if (headerSave?.length > 0) {
        this.headerList = headerSave.length !== this.headers.length ? [...this.headers] : [...headerSave]
      } else {
        this.headerList = [...this.headers]
      }

      this.filterOpts = []
      let objetoFilter = '{'
      let objetoHeader = '{'
      this.headerList.map(header => {
        if (header.filterable) {
          this.filterOpts.push({
            value: '',
            key: header.value,
            checked: false,
            text: header.text,
            unidade: []
          })
          objetoFilter += `"${header.value}": [],`
        }
        objetoHeader += `"${header.value}": ${header.groupable},`
      })
      if (objetoFilter.length > 1) {
        this.filters = JSON.parse(objetoFilter.substring(0, objetoFilter.length - 1) + '}')
      }
      this.headerName = JSON.parse(objetoHeader.substring(0, objetoHeader.length - 1) + '}')
    },
    methods: {
      exportExcel () {
        this.excel = new JsonToExcel(this.valueFiltered, this.headerList)
      },
      optionsFilter (key) {
        const options = []
        this.value.map(item => {
          const hv = getProperty(item, key)
          if (hv !== '' && !options.filter(option => option === hv).length) options.push(hv)
        })
        options.sort()
        return options
      },
      sortTheHeadersAndUpdateTheKey (evt) {
        const { nome } = this.$user.get()
        const headersTmp = this.headerList
        const oldIndex = evt.oldIndex
        const newIndex = evt.newIndex
        if (newIndex >= headersTmp.length) {
          let k = newIndex - headersTmp.length + 1
          while (k--) {
            headersTmp.push(undefined)
          }
        }
        headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0])
        this.anIncrementoNumber += 1
        localStorage.setItem('CrudList-' + hash(this.headers) + '-' + nome, JSON.stringify(headersTmp))
      },
      groupNameHeader (keyValue) {
        let groupName = ''
        this.headers.forEach(ob => {
          if (ob.value === keyValue[0]) {
            groupName = ob.text
          }
        })
        return groupName
      },
      setGroupHeader (keyValue) {
        if (keyValue === '') {
          this.groupHeader = []
        } else {
          this.groupHeader = [keyValue]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.v-data-table > .v-data-table__wrapper > table>tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}

.v-menu__content {
  border-radius: 16px !important;
}

</style>

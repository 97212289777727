<template>
  <v-row
    :class="baseClass"
    align="center"
    justify="center"
  >
    <v-progress-circular
      style="margin-bottom: 8px; margin-top: 8px;"
      :size="75"
      color="teal"
      :width="8"
      :value="value"
    >
      {{ value }}%
    </v-progress-circular>
    <span style="font-size: 18px"> {{ message }}</span>
  </v-row>
</template>
<script>
  export default {
    props: {
      message: {
        type: String,
        default: () => 'Aguarde... Carregando os dados.',
      },
      baseClass: {
        type: String,
        default: () => 'fill-height ma-5 flex-column',
      },
    },
    data () {
      return {
        interval: {},
        value: 0,
      }
    },
    beforeDestroy () {
      clearInterval(this.interval)
    },
    mounted () {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0)
        }
        this.value += 10
      }, 1500)
    },
  }
</script>

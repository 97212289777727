import axios from 'axios'
import store from '@/vuex/store'
import Swal from 'sweetalert2'

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL_PROD : process.env.VUE_APP_API_URL_DEV

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

const cancelSource = {
  source: axios.CancelToken.source(),
}

axios.interceptors.request.use((config) => {
  config.cancelToken = cancelSource.source.token
  return config
}, (_err) => {
  return Promise.reject(_err)
})

axios.interceptors.response.use(function (response) {
  if (response.data && response.data.error) {
    return Promise.reject(response)
  }
  return response
}, (error) => {
  if (error.response.status === 401) {
    if (store.state.user) {
      Swal.fire('ERRO', 'Sua sessão expirou ou você não tem autorização para acessar este componente. Faça o login novamente.', 'error')
    }
  }
  return Promise.reject(error.response)
})

export { axios, cancelSource }

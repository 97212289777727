<template>
  <div>
    <v-row>
      <v-col
        v-if="!$exclude['nome']"
        cols="12"
        md="6"
      >
        <v-text-field
          v-if="inputOutlined"
          v-model="nome"
          dense
          outlined
          autocomplete="off"
          :label="`Nome${$optional.nome ? '' : '*'}`"
          validate-on-blur
          :rules="[...($optional.nome ? [] : [rules.required])]"
          :disabled="$disabled.nome"
        />
        <v-text-field
          v-else
          v-model="nome"
          dense
          autocomplete="off"
          :label="`Nome${$optional.nome ? '' : '*'}`"
          validate-on-blur
          :rules="[...($optional.nome ? [] : [rules.required])]"
          :disabled="$disabled.nome"
        />
      </v-col>

      <v-col
        v-if="!$exclude['email']"
        cols="12"
        md="6"
      >
        <v-text-field
          v-if="inputOutlined"
          v-model="email"
          outlined
          dense
          autocomplete="new-email"
          :rules="[...($optional.email ? [] : [rules.required]), rules.email]"
          :label="`Email${$optional.email ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.email"
          @input="email = email.toLowerCase()"
        />
        <v-text-field
          v-else
          v-model="email"
          dense
          autocomplete="new-email"
          :rules="[...($optional.email ? [] : [rules.required]), rules.email]"
          :label="`Email${$optional.email ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.email"
          @input="email = email.toLowerCase()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="!$exclude['cpf']"
        cols="12"
        md="3"
      >
        <v-text-field
          v-if="inputOutlined"
          v-model="cpf"
          v-mask="maskCPF"
          outlined
          dense
          autocomplete="off"
          :label="`CPF${$optional.cpf ? '' : '*'}`"
          :error-messages="cpfErrors"
          :disabled="$disabled.cpf"
          :loading="$v.cpf.$pending"
          @blur="$v.cpf.$touch()"
          @focus="$v.$reset"
          @input="cpfTouch"
        >
          <template v-slot:append>
            <v-icon
              v-if="cpfRequiredValid"
              color="success"
            >
              mdi-check-circle
            </v-icon>
          </template>
        </v-text-field>
        <v-text-field
          v-else
          v-model="cpf"
          v-mask="maskCPF"
          outlined
          dense
          autocomplete="off"
          :label="`CPF${$optional.cpf ? '' : '*'}`"
          :error-messages="cpfErrors"
          :disabled="$disabled.cpf"
          :loading="$v.cpf.$pending"
          @blur="$v.cpf.$touch()"
          @focus="$v.$reset"
          @input="cpfTouch"
        >
          <template v-slot:append>
            <v-icon
              v-if="cpfRequiredValid"
              color="success"
            >
              mdi-check-circle
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        v-if="!$exclude['rg']"
        cols="12"
        md="3"
      >
        <v-text-field
          v-if="inputOutlined"
          v-model="rg"
          outlined
          dense
          autocomplete="off"
          :rules="[...($optional.rg ? [] : [rules.required])]"
          :label="`RG${$optional.rg ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.rg"
        />
        <v-text-field
          v-else
          v-model="rg"
          dense
          autocomplete="off"
          :rules="[...($optional.rg ? [] : [rules.required])]"
          :label="`RG${$optional.rg ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.rg"
        />
      </v-col>
      <v-col
        v-if="!$exclude['data_nascimento']"
        cols="12"
        md="3"
      >
        <v-text-field
          v-if="inputOutlined"
          v-model="data_nascimento"
          v-mask="maskData"
          outlined
          dense
          autocomplete="off"
          :rules="[
            ...($optional.data_nascimento ? [] : [rules.required]),
            rules.validDate,
            rules.isNotFuture,
          ]"
          :label="`Data de nascimento${$optional.data_nascimento ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.data_nascimento"
        />
        <v-text-field
          v-else
          v-model="data_nascimento"
          v-mask="maskData"
          dense
          autocomplete="off"
          :rules="[
            ...($optional.data_nascimento ? [] : [rules.required]),
            rules.validDate,
            rules.isNotFuture,
          ]"
          :label="`Data de nascimento${$optional.data_nascimento ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.data_nascimento"
        />
      </v-col>
      <v-col
        v-if="!$exclude['sexo']"
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-if="inputOutlined"
          v-model="sexo"
          outlined
          dense
          autocomplete="off"
          :items="optionSexo"
          :rules="[...($optional.sexo ? [] : [rules.required])]"
          :label="`Sexo${$optional.sexo ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.sexo"
        />
        <v-autocomplete
          v-else
          v-model="sexo"
          dense
          autocomplete="off"
          :items="optionSexo"
          :rules="[...($optional.sexo ? [] : [rules.required])]"
          :label="`Sexo${$optional.sexo ? '' : '*'}`"
          validate-on-blur
          :disabled="$disabled.sexo"
        />
      </v-col>
      <slot name="append" />
    </v-row>
  </div>
</template>
<script>
  import { mapFields } from '../../utils/formMapFields'
  import { required } from 'vuelidate/lib/validators'
  import { validarCPF } from '../../utils/validacoes'
  import rules from '../../utils/formRules'
  export default {
    name: 'PessoaFisicaBase',
    props: {
      optional: {
        type: Array,
        default: () => {
          return []
        },
      },
      exclude: {
        type: Array,
        default: () => {
          return []
        },
      },
      notEditable: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Array,
        default: () => {
          return []
        },
      },
      storeModule: {
        type: String,
        default: 'form',
      },
      inputOutlined: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        name: null,
        valid: true,
        rules: rules,
        optionSexo: ['Masculino', 'Feminino'],
        maskData: '##/##/####',
        maskCPF: '###.###.###-##',
      // cpfExists: false,
      }
    },
    computed: {
      ...mapFields({
        fields: [
          'nome',
          'cpf',
          'email',
          'data_nascimento',
          'sexo',
          'rg',
        ],
      }),
      cpfErrors () {
        const errors = []
        if (!this.$v.cpf.$dirty) return errors
        !this.optional.cpf && !this.$v.cpf.required && errors.push('Obrigatório')
        !this.$v.cpf.formato && errors.push('Inválido')
        return errors
      },
      cpfRequiredValid () {
        return this.cpf && this.$v.cpf.$dirty && !this.$v.cpf.$error
      },
      $disabled () {
        const disabledFields = this.disabled
          ? Object.fromEntries(
            this.$fields.map(field => [
              field,
              !!this.disabled.find(e => e === field),
            ]),
          )
          : null
        return disabledFields
      },
    },
    validations () {
      return {
        cpf: {
          required: this.$optional.cpf || required,
          formato: v => !v || validarCPF(v),
        },
        nome: {
          ...(this.$optional.nome || required),
        },
        email: {
          ...(this.$optional.email || required),
        },
        rg: {
          ...(this.$optional.rg || required),
        },
        sexo: {
          ...(this.$optional.sexo || required),
        },
        data_nascimento: {
          ...(this.$optional.data_nascimento || required),
        },
      }
    },
    beforeCreate () {
      this.$fields = ['nome', 'cpf', 'email', 'data_nascimento', 'sexo', 'rg']
      const { exclude, optional, disabled, notEditable } = this.$options.propsData

      this.$exclude = exclude
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, !!exclude.find(e => e === field)]
          }),
        )
        : {}

      this.$optional = optional
        ? Object.fromEntries(
          this.$fields.map(field => {
            return [field, optional.find(e => e === field) ? true : null]
          }),
        )
        : {}

      this.$disabledFields = disabled ? Object.fromEntries(this.$fields.map(field => {
        return [field, disabled.find(e => e === field) ? true : null]
      })) : {}

      this.$notEditable = notEditable ? Object.fromEntries(this.$fields.map(field => {
        return [field, !!notEditable.find(e => e === field)]
      })) : {}
    },
    methods: {
      cpfTouch () {
        if (this.cpf.length === 14) this.$v.cpf.$touch()
      },
      validate () {
        this.$v.$touch()
        return !this.$v.$invalid
      },
    },
  }
</script>
;

<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    max-height="400px"
    eager
  >
    <template v-slot:activator="{ on }">
      <v-hover
        style="max-height: 220px; max-width: 165px;"
        class="pa-0 ma-1"
      >
        <template v-slot:default="{ hover }">
          <div>
            <!-- <v-btn @click="ver">
              Salva
            </v-btn> -->
            <v-card
              class="pa-0 ma-0"
              fluid
              outlined
            >
              <base-file-drop
                ref="file-drop"
                :disabled="disableOnFill && loaded_image"
                @files-selected="handleFiles"
              >
                <v-img
                  class="rounded-sm"
                  height="220px"
                  :src="showImage"
                />
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#d2d2d2"
                    :z-index="1"
                  >
                    <div
                      v-if="!loaded_image"
                      class="d-flex flex-column align-center pa-2 pb-5"
                    >
                      <!-- <v-file-input
                          :hide-input="true"
                        >
                          <template v-slot:prepend-inner> -->
                      <v-btn
                        class="pa-8"
                        icon
                        color="primary lighten-1"
                        @click="selectFiles"
                      >
                        <v-icon
                          large
                        >
                          mdi-camera
                        </v-icon>
                      </v-btn>
                      <span class="primary--text text--lighten-1 text-center">
                        <b>Escolha</b> uma imagem do computador
                      </span>
                      <span class="primary--text text--lighten-1 text-center my-2">
                        ou
                      </span>
                      <span
                        class="primary--text text--lighten-1 text-center mt-1"
                      >
                        <b>arraste</b> um arquivo para cá
                      </span>
                      <!-- </template>
                        </v-file-input> -->
                    </div>
                    <div v-else>
                      <v-btn
                        v-if="editable"
                        class="pa-8"
                        icon
                        color="primary lighten-1"
                        v-on="on"
                      >
                        <v-icon
                          large
                        >
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="pa-8"
                        icon
                        color="primary lighten-1"
                        @click="reset"
                      >
                        <v-icon
                          large
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </base-file-drop>
            </v-card>
          </div>
        </template>
      </v-hover>
    </template>
    <v-card
      class="pa-3"
      justify="center"
      align="center"
    >
      <v-card-title class="mb-2 justify-center">
        <span
          class="headline"
          style="color: #444; font-size: 24px"
        >{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-card
          outlined
          class="mb-2 pa-0"
        >
          <vue-cropper
            ref="cropper"
            preview="#avatar-prev"
            style="overflow: hidden"
            :guides="false"
            :view-mode="2"
            :aspect-ratio="0.75"
            :min-container-width="300"
            :min-container-height="400"
            drag-mode="move"
            :responsive="false"
            :auto-crop-area="1"
            :background="true"
            :rotatable="true"
            alt="Source Image"
            @ready="crop"
          />
        </v-card>
        <small>Recorte e ajuste o tamanho do imagem</small>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"

          outlined
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"

          @click="() => { crop(); dialog = false }"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  // import axios from 'axios'
  export default {
    components: {
      VueCropper,
    },
    model: {
      prop: 'image',
      event: 'setImage',
    },
    props: {
      image: {
        type: [String, Object],
        default: null,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      noData: {
        type: String,
        default: null,
      },
      disableOnFill: {
        type: Boolean,
        default: true,
      },
      dialogTitle: {
        type: String,
        default: 'Imagem',
      },
    },
    data () {
      return {
        dialog: false,
        loading: true,
        overlay: false,
        cropped_image: null,
        $dirty: false,
      }
    },
    computed: {
      showImage () {
        return this.cropped_image || this.noData
      },
      loaded_image () {
        return !!this.cropped_image
      },
    },
    created () {
      this.$dirty = false
    },
    mounted () {
      if (this.image) {
        this.cropped_image = this.image
        this.$refs.cropper.replace(this.image)
      }
    },
    methods: {
      handleFiles ($event) {
        const reader = new FileReader($event[0])
        let image = null
        reader.onload = (e) => {
          image = e.target.result
          this.dialog = true
          this.$refs.cropper.replace(image)
          this.$dirty = true
        }
        this.$emit('upload', image)
        reader.readAsDataURL($event[0])
      },
      selectFiles () {
        this.$refs['file-drop'].activate()
      },

      crop () {
        const cpcanvas = this.$refs.cropper.getCroppedCanvas({
          maxWidth: 1024,
          maxHeight: 1024,
          fillColor: '#fff',
        })
        this.cropped_image = cpcanvas.toDataURL('image')
        this.$emit('setImage', this.cropped_image)
      },
      reset () {
        this.$refs.cropper.reset()
        this.cropped_image = null
        this.$dirty = true
        this.$emit('setImage', null)
        this.$emit('delete', null)
      },
    },
  }
</script>
